import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export default {
    data() {
        return {
            records: [],
        };
    },

    computed: {
        counts() {
            return this.charges.length > 0
                ? [
                      {
                          label: "Felonies",
                          color: this.getDegreeColor("FELONY"),
                          colorOption: 1,
                          icon: "mdi-gavel",
                          count: this.records.filter(
                              (item) => item.degree === "FELONY"
                          ).length,
                      },
                      {
                          label: "Misdemeanors",
                          color: this.getDegreeColor("MISDEMEANOR"),
                          colorOption: 2,
                          icon: "mdi-scale-balance",
                          count: this.records.filter(
                              (item) => item.degree === "MISDEMEANOR"
                          ).length,
                      },
                      {
                          label: "Violations",
                          color: this.getDegreeColor("VIOLATION"),
                          colorOption: 3,
                          icon: "mdi-script",
                          count: this.records.filter(
                              (item) => item.degree === "VIOLATION"
                          ).length,
                      },
                      {
                          label: "Evictions",
                          color: this.getDegreeColor("EVICTION"),
                          colorOption: 4,
                          icon: "mdi-home-export-outline",
                          count: this.records.filter(
                              (item) => item.type === "eviction"
                          ).length,
                      },
                  ]
                : [];
        },
        evictions() {
            return this.records
                .filter((item) => item.type === "eviction")
                .map((item) => {
                    const { fileDate } = this.getDates(item.detail);
                    const info = this.getInfo(item);
                    return {
                        ...item,
                        fileDate,
                        info,
                    };
                });
        },
        charges() {
            return this.records
                .filter((item) => item.type === "crime")
                .sort((a, b) => a.degree > b.degree)
                .map((item) => {
                    item.degreeColor = this.getDegreeColor(item.degree);
                    const {
                        color: dispositionColor,
                        icon: dispositionIcon,
                    } = this.getDisposition(item.disposition);

                    const {
                        dispositionDate,
                        fileDate,
                        offenseDate,
                    } = this.getDates(item.detail);

                    const info = this.getInfo(item);

                    item = {
                        ...item,
                        dispositionDate,
                        fileDate,
                        offenseDate,
                        dispositionColor,
                        dispositionIcon,
                        info,
                    };
                    return item;
                });
        },
    },
    methods: {
        getInfo(item) {
            let infoItems = [
                {
                    label: "Category",
                    type: "chip",
                    text: item.name,
                    icon: "mdi-form-select",
                },
                {
                    label: "Case ID",
                    type: "label",
                    text: item.detail.caseId,
                    icon: "mdi-briefcase",
                },
            ];
            if (this.$_.has(item, "detail.statute")) {
                infoItems.push({
                    label: "Statute",
                    type: "label",
                    text: item.detail.statute,
                    icon: "mdi-script-text",
                });
            }
            if (
                this.$_.has(item, "detail.codeCaseType") &&
                this.$_.get(item, "type") === "eviction"
            ) {
                infoItems.push({
                    label: "Case Type",
                    type: "label",
                    text: item.detail.codeCaseType,
                    icon: "mdi-briefcase-check",
                });
            }
            if (this.$_.has(item, "detail.judgement")) {
                infoItems.push({
                    label: "Judgement",
                    type: "label",
                    text: item.detail.judgement,
                    icon: "mdi-gavel",
                });
            }
            return infoItems;
        },
        getDates(item) {
            dayjs.extend(relativeTime);

            item.fileDate = this.$_.get(item, "fileDate")
                .split(" ")
                .shift();
            item.offenseDate = this.$_.get(item, "offenseDate", "")
                .split(" ")
                .shift();
            item.dispositionDate =
                item.dispositions && item.dispositions.length > 0
                    ? this.$_.get(item.dispositions[0], "dispositionDate", "")
                          .split(" ")
                          .shift()
                    : "";
            let fileDate =
                item.fileDate.length === 0
                    ? null
                    : {
                          icon: "mdi-calendar",
                          label: "File Date",
                          date:
                              item.fileDate.length > 0
                                  ? dayjs(item.fileDate).format("MM/DD/YYYY")
                                  : "",
                          from:
                              item.fileDate.length > 0
                                  ? dayjs(item.fileDate).fromNow()
                                  : "",
                      };
            let offenseDate =
                item.offenseDate.length === 0
                    ? null
                    : {
                          icon: "mdi-calendar-cursor",
                          label: "Offense Date",
                          date:
                              item.offenseDate.length > 0
                                  ? dayjs(item.offenseDate).format("MM/DD/YYYY")
                                  : "",
                          from:
                              item.offenseDate.length > 0
                                  ? dayjs(item.offenseDate).fromNow()
                                  : "",
                      };
            let dispositionDate =
                item.dispositionDate.length === 0
                    ? null
                    : {
                          icon: "mdi-calendar-check",
                          label: "Disposition Date",
                          date:
                              item.dispositionDate.length > 0
                                  ? dayjs(item.dispositionDate).format(
                                        "MM/DD/YYYY"
                                    )
                                  : "",
                          from:
                              item.dispositionDate.length > 0
                                  ? dayjs(item.dispositionDate).fromNow()
                                  : "",
                      };
            return { fileDate, offenseDate, dispositionDate };
        },
        getDegreeColor(degree) {
            switch (degree) {
                case "VIOLATION":
                    return "primary darken-2";
                case "MISDEMEANOR":
                    return "warning darken-2";
                case "FELONY":
                    return "error darken-2";
                default:
                    return "grey darken-2";
            }
        },
        getDisposition(disposition) {
            switch (disposition) {
                case "DISMISSED":
                    return {
                        color: "grey darken-2",
                        icon: "mdi-close-circle",
                    };
                case "CONVICTED":
                    return {
                        color: "accent darken-2",
                        icon: "mdi-check-circle",
                    };
                default:
                    return "grey";
            }
        },
    },
};
